.errorpage {
    color: #fff;
    font-weight: bold;
}

.errorpage .wrapper {
    margin-top: 20%;
    padding: 10%;
    background-color: rgb(10,210,199,0.4);
    border-radius: 1rem;
}

.errorpage .error-code {
    font-size: 100px;
}

.errorpage .text-blue {
    color: #0AD2C7;
}

.errorpage button {
    border-radius: 0.7rem;
    width: 50%;
    height: 40px;
    background-color: #011133;
    color: #ffff;
    margin-top: 20px;
    font-weight: bold;
    box-shadow: 0px 2px 15px #08193D;
    border: 0;
}

@media screen and (min-device-width: 1024px) {
    .errorpage .wrapper {
        margin-top: 5%;
    }

    .errorpage button {
        width: 25%;
    }
}