@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.landingpage{
    color: white;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.landingpage h1 {
    margin-top: 50px;
}

.landingpage p {
    text-align: center;
}

.landingpage div{
    text-align: center;
    justify-content: space-between;
}

.landingpage button:focus {
    color: #ffff;
}

.landingpage button:hover {
    color: #ffff;
}

.landingpage .btn-signin{
    margin-top: 50%;
}

.landingpage .footer {
    margin-top: 20%;
    font-size: 13px;
    font-weight: normal;
    color: #011133;
}

@media screen and (min-device-width: 1024px) {

    .landingpage .btn-signin{
        margin-top: 15%;
    }

    .landingpage .footer {
        margin-top: 15%;
    }
}